import {IPost} from "./IPost";
import moment from "moment";

export class Post implements IPost {
  id: number = 0;
  date: number = 0;
  link: string = '';
  channel_id: number = 0;
  forwarded_from: string = '';
  is_deleted: number = 0;
  deleted_at: number = 0;
  group_id: number = 0;
  text: string = '';
  viewsCount: number = 0;
  sharesCount: number = 0;
  commentsCount: number = 0;
  reactionsCount: number = 0;
  forwardsCount: number = 0;
  mentionsCount: number = 0;

  constructor(o: Object) {
    Object.entries(o).forEach(([k, v]: [string, any]) => {
      if (Object.hasOwn(this, k)) {
        const keyName = k as keyof IPost;
        try {
          // @ts-ignore
          this[keyName] = (this[keyName].constructor)(v.toString())
        } catch {}
      }
    })
  }

  public get publicationDate(): moment.Moment | null {
    if(this.date === null) return null;
    return moment.unix(Number(this.date));
  }

  public get publicationTime(): moment.Moment | null {
    if(this.date === null) return null;
    return moment.unix(Number(this.date));
  }

  public get deletedAtDate(): moment.Moment | null {
    if(this.deleted_at === null) return null;
    return moment.unix(Number(this.deleted_at));
  }

  public get deletedAtTime(): moment.Moment | null {
    if(this.deleted_at === null) return null;
    return moment.unix(Number(this.deleted_at));
  }

  public get channelId(): number | null {
    if(this.channel_id === 0) return null;
    return this.channel_id;
  }

  public get forwardedFrom(): string | null {
    if(this.forwarded_from === '') return null;
    return this.forwarded_from;
  }

  public get isDeleted(): boolean {
    return !!this.is_deleted
  }

  public get groupId(): number | null {
    if(this.group_id === 0) return null;
    return this.group_id;
  }

}
