import React, {useEffect, useState} from "react";
import {Card, Empty, Progress, Space, Spin, Tag, Tooltip} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";

import {withTranslation} from "react-i18next";
import QuotationDao from "@repo/dao/QuotationDao"
import withAntdToken from "@components/wrappers/withAntdToken";
import {Quotation} from "@repo/types/Quotation";

const BalanceError: React.FC<{t: (s:string) => string}> = ({t}) => (
  <Empty
    description={t('Balance.empty')}
    style={{marginTop: '80px', marginBottom: '80px'}}
  />
)

const BalanceEmpty: React.FC<{t: (s:string) => string}> = ({t}) => (
  <Empty
    description={t('Balance.empty')}
    style={{marginTop: '80px', marginBottom: '80px'}}
  />
)

const QuotationCircle: React.FC<any> = ({name, spent, limit}) => (
  <>
    <Tooltip
      style={{display: "block"}}
      title={`${spent} из ${limit}`}>
      <Progress
        strokeColor="#9261f1"
        style={{marginLeft: '6px', marginRight: '6px', fontSize: '1px'}}
        type="dashboard"
        percent={100 - spent * 100 / limit}
        format={() => limit - spent}
      />
    </Tooltip>
    <div style={{
      marginRight: '6px',
      marginTop: -10,
      paddingBottom: 10,
      width: '100%',
      textAlign: 'center'
    }}>
      {name}
    </div>
  </>
)

enum Status {
  isEmpty,
  isLoading,
  isContent,
  isError
}

const Balance: React.FC<any> = ({t}) => {
  const [status, setStatus] = useState<Status>(Status.isLoading)
  const [tariffs, setTariffs] = useState<Array<Quotation>>([])

  useEffect(() => {
    const request = async () => {
      const response: any = await QuotationDao.getAll()
      // const response: any = await Request(ApiURL + "entity/quotation/_all", {
      //   headers: { "Content-type": "application/json" },
      //   method: "GET"
      // })
      setTariffs(
        response
          .sort((a: Quotation,b: Quotation) => a.expiredAt > b.expiredAt ? -1 : 1)
      )
      setStatus(Status.isContent)
    }
    request()
  },[])



  return (
    <>
      <h2>{t('Balance.title')}</h2>
      <Spin spinning={ status === Status.isLoading}>
        <Space direction="horizontal" size="large" align="start">
          { status === Status.isContent && (
            tariffs.map((tariff: any, index: any) => {
              return (
                <Card title={tariff.title ?? t('Balance.tariff.nameless')} size="small" key={index}>
                  <div style={{ height: '20px' }} ></div>
                  {(tariff.spentChannelsLimit > 0) && (
                    <Space.Compact direction="vertical">
                      <QuotationCircle
                        name={t('Balance.tariff.channels')}
                        spent={tariff.spentChannelsCount}
                        limit={tariff.spentChannelsLimit}
                      />
                    </Space.Compact>
                  )}
                  {(tariff.spentRequestsLimit > 0) && (
                    <Space.Compact direction="vertical">
                      <QuotationCircle
                        name={t('Balance.tariff.requests')}
                        spent={tariff.spentRequestsCount}
                        limit={tariff.spentRequestsLimit}
                      />
                    </Space.Compact>
                  )}
                  {(tariff.spentWordsLimit > 0) && (
                    <Space.Compact direction="vertical">
                      <QuotationCircle
                        name={t('Balance.tariff.words')}
                        spent={tariff.spentWordsCount}
                        limit={tariff.spentWordsLimit}
                      />
                    </Space.Compact>
                  )}
                  {tariff.expiredAt && tariff.expiredAt > (new Date()).valueOf() * 0.001 &&
                      <p style={{marginTop: 20, marginBottom: 0}}>
                          <Tag icon={<CheckCircleOutlined />} color="success">
                            { t('Balance.tariff.isActive') }
                          </Tag>
                        { t('Balance.tariff.activeTill') } {
                        new Date(tariff.expiredAt * 1000).toLocaleString()
                      }
                      </p>
                  }
                  {tariff.expiredAt && tariff.expiredAt < (new Date()).valueOf() * 0.001 &&
                      <p style={{marginTop: 20, marginBottom: 0}}>
                          <Tag icon={<CloseCircleOutlined />} color="error">
                            { t('Balance.tariff.isExpired') }
                          </Tag>
                        { t('Balance.tariff.expiredAt') } {
                        new Date(tariff.expiredAt * 1000).toLocaleString()
                      }
                      </p>
                  }
                </Card>
              )
            })
          )}

          { status === Status.isEmpty && <BalanceEmpty t={t} />}
          { status === Status.isError && <BalanceError t={t} />}
        </Space>
      </Spin>
    </>
  );
}

export default withAntdToken(withTranslation()(Balance))
