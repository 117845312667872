import {Grid, theme} from 'antd';

const { useBreakpoint } = Grid;

function withAntdToken(Component: any) {
  function ComponentWithTokenProp(props: any) {
    const { token } = theme.useToken();
    const screens = useBreakpoint();
    return (
      <Component
        {...props}
        screens={screens}
        token={{...token}}
      />
    );
  }

  return ComponentWithTokenProp;
}

export default withAntdToken;
