import {IChannel} from "@repo/types/IChannel";

export class Channel implements IChannel {
  id: number = 0;
  tg_id: number = 0;
  about: string = '';
  title: string = '';
  active_usernames: string[] = [];
  username: string = '';
  category: string = '';
  country: string = '';
  image100: string = '';
  image640: string = '';
  language: string = '';
  link: string = '';
  participants_count: number = 0;
  peer_type: string = '';
  adv_post_reach_12h: number = 0;
  adv_post_reach_24h: number = 0;
  adv_post_reach_48h: number = 0;
  avg_post_reach: number = 0;
  ci_index: number = 0;
  daily_reach: number = 0;
  er_percent: number = 0;
  err24_percent: number = 0;
  err_percent: number = 0;
  forwards_count: number = 0;
  mentioning_channels_count: number = 0;
  mentions_count: number = 0;
  posts_count: number = 0;

  constructor(o: Object) {
    Object.entries(o).forEach(([k, v]: [string, any]) => {
      if (Object.hasOwn(this, k)) {
        const keyName = k as keyof IChannel;
        try {
          // @ts-ignore
          this[keyName] = (this[keyName].constructor)(v.toString())
        } catch {}
      }
    })
  }

  public get tgId(): number { return this.tg_id }
  public get activeUsernames(): Array<string> { return this.active_usernames }
  public get participantsCount(): number { return this.participants_count }
  public get peerType(): string { return this.peer_type }
  public get advPostReach12h(): number { return this.adv_post_reach_12h }
  public get advPostReach24h(): number { return this.adv_post_reach_24h }
  public get advPostReach48h(): number { return this.adv_post_reach_48h }
  public get avgPostReach(): number { return this.avg_post_reach }
  public get ciIndex(): number { return this.ci_index }
  public get dailyReach(): number { return this.daily_reach }
  public get erPercent(): number { return this.er_percent }
  public get err24Percent(): number { return this.err24_percent }
  public get errPercent(): number { return this.err_percent }
  public get forwardsCount(): number { return this.forwards_count }
  public get mentioningChannelsCount(): number { return this.mentioning_channels_count }
  public get mentionsCount(): number { return this.mentions_count }
  public get postsCount(): number { return this.posts_count }

}
