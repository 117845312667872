import {IQuotation} from "./IQuotation";

export class Quotation implements IQuotation {
    expiredAt: number = 0;
    serviceKey: string = '';
    spentChannels: string = '0/0';
    spentRequests: string = '0/0';
    spentWords: string = '0/0';
    title: string = '';

    constructor(o: Object) {
        Object.entries(o).forEach(([k, v]: [string, any]) => {
            if(Object.hasOwn(this, k)) {
                const keyName = k as keyof Quotation;
                // @ts-ignore
                this[keyName] = (this[keyName].constructor) (v)
            }
        })
    }

    public get expiredAtDate(): Date {
        const d = new Date(0);
        d.setUTCSeconds(this.expiredAt)
        return d;
    }

    public get spentChannelsCount(): number {
        return (Number) (this.spentChannels.split('/').at(0))
    }

    public get spentChannelsLimit(): number {
        return (Number) (this.spentChannels.split('/').at(1))
    }

    public get spentRequestsCount(): number {
        return (Number) (this.spentRequests.split('/').at(0))
    }

    public get spentRequestsLimit(): number {
        return (Number) (this.spentRequests.split('/').at(1))
    }

    public get spentWordsCount(): number {
        return (Number) (this.spentWords.split('/').at(0))
    }

    public get spentWordsLimit(): number {
        return (Number) (this.spentWords.split('/').at(1))
    }
}
