import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React from "react";

function withRouter(Component: any) {
  function ComponentWithRouterProps(props: any) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component {...props} location={location} params={params} navigate={navigate} />
    );
  }

  return ComponentWithRouterProps;
}

export default withRouter;
