import React, {useState} from "react";
import {
	Avatar,
	Badge,
	Button,
	Empty,
	Input, List,
	Typography,
	Popover,
	Result,
	Space,
	Spin,
	Tooltip,
	Table,
	Flex, notification
} from "antd";
import {
	SettingOutlined,
	QuestionCircleOutlined,
	UpOutlined,
	DownOutlined,
	EditOutlined,
	CopyOutlined,
	CheckOutlined,
	CloseOutlined
} from "@ant-design/icons";

import {useTranslation} from "react-i18next";

// @ts-ignore
import UsersManualExport from '@documents/UsersManual_ExportChannelStats.pdf';
import ChannelDao from "@repo/dao/ChannelDao";
import {IChannel} from "@repo/types/IChannel";
import uniqid from 'uniqid';

const { Paragraph, Text } = Typography;

enum Status {
	isBasic,
	isEmpty,
	isLoading,
	isContent,
	isError
}

const UsersManualExportButton: React.FC<any> = ({t}) => (
	<div style={{ textAlign: "left", width: '100%' }}>
		<Text color="#d9d9d9">{ t(`ExportChannelStats.search.pleaseRead`) }
			<a href={UsersManualExport} rel="noopener noreferrer" target="_blank">
				<Button
					type="link"
					style={{paddingLeft: 4, paddingRight: 0, color: "#9261f1"}}>
					{ t(`ExportChannelStats.search.manual`) }
				</Button>
			</a>
		</Text>
	</div>
)

const SettingPopover: React.FC<any> = ({open, onOpenChange}) => (
	<Popover
	placement="bottomRight"
	trigger="click"
	open={open}
	onOpenChange={onOpenChange}
	>
		<Button size="large" icon={<SettingOutlined />} />
	</Popover>
)

const SpinLoading: React.FC<any> = () => (
	<Spin
	tip="Loading"
	size="large"
	style={{marginTop: -40, marginBottom: 0}}
	>
		<div style={{
			padding: 50,
			marginTop: 80,
			marginBottom: 80,
			background: 'rgba(0, 0, 0, 0.05)',
			borderRadius: 4,
		}} />;
	</Spin>
)

const ExportChannelStats: React.FC<any> = () => {
	const columns: Array<object> = [
		{ title: 'Статус', dataIndex: 'status', key: 'status' },
		{ title: 'Площадка', dataIndex: 'title', key: 'title' },
		{ title: 'URL', dataIndex: 'link', key: 'link' },
		{ title: 'Тематика', dataIndex: 'category', key: 'category' },
		{ title: 'Подписчики', dataIndex: 'participantsCount', key: 'participantsCount' },
		{ title: 'Охват 24', dataIndex: 'advPostReach24h', key: 'advPostReach24h' },
		{ title: 'Охват 48', dataIndex: 'advPostReach48h', key: 'advPostReach48h' },
		{ title: 'Цитируемость', dataIndex: 'ciIndex', key: 'ciIndex' },
		{ title: 'ER', dataIndex: 'erPercent', key: 'erPercent' },
		{ title: 'Показы', dataIndex: 'avgPostReach', key: 'avgPostReach' },
		{ title: 'Username', dataIndex: 'username', key: 'username' },
		{ title: 'Страна', dataIndex: 'country', key: 'country' },
		{ title: 'Язык', dataIndex: 'language', key: 'language' },
		{ title: 'Тип', dataIndex: 'peerType', key: 'peerType' },
		{ title: 'Охват дневной', dataIndex: 'dailyReach', key: 'dailyReach' },
		{ title: 'ERR', dataIndex: 'errPercent', key: 'errPercent' },
		{ title: 'ERR 24', dataIndex: 'err24Percent', key: 'err24Percent' },
		{ title: 'Репостов канала', dataIndex: 'forwardsCount', key: 'forwardsCount' },
		{ title: 'Упоминаний каналами', dataIndex: 'mentioningChannelsCount', key: 'mentioningChannelsCount' },
		{ title: 'Упоминаний всего', dataIndex: 'mentionsCount', key: 'mentionsCount' },
		{ title: 'Охват 12', dataIndex: 'advPostReach12h', key: 'advPostReach12h' },
		{ title: 'Постов', dataIndex: 'postsCount', key: 'postsCount' },
	];

	const ColumnsForBuffer: Array<object> = [
		{ title: 'Площадка', key: 'title', format: (e: any) => e },
		{ title: 'URL', key: 'link', format: (e: any) => e },
		{ title: 'Тематика', key: 'category', format: (e: any) => e },
		{ title: 'Подписчики', key: 'participantsCount', format: (e: any) => e },
		{ title: 'Охват 24', key: 'advPostReach24h', format: (e: any) => e },
		{ title: 'Охват 48', key: 'advPostReach48h', format: (e: any) => e },
		{ title: 'Цитируемость', key: 'ciIndex', format: (e: any) => String(e).replace('.',',') },
		{ title: 'ER', key: 'erPercent', format: (e: any) => String(e).replace('.',',') },
	];

	const [status, setStatus] = useState<Status>(Status.isEmpty)
	const [search, setSearch] = useState<Array<string>>(JSON.parse(localStorage.getItem('ExportChannelStats.search') || '[]'))
	const [content, setContent] = useState<Array<any>>([])
	const [settingsOpened, setSettingsOpened] = useState<Number>(0)
	const [searchArrayOpened, setSearchArrayOpened] = useState<Number>(0)

	const {t} = useTranslation()
	const [api, contextHolder] = notification.useNotification();

	const handleSettingsOpenedChange = (value: any) => setSettingsOpened(Number(value));
	const handleSearchArrayOpenedChange = (value: any) => setSearchArrayOpened(Number(value));

	const handleSearchClear = () => {
		localStorage.removeItem('ExportChannelStats.search')
		setSearch([])
	}

	const handleSearchChange = (value: string) => {
		const test1 = /(t|telegram)\.(me|dog)\/(joinchat\/|\+)?([\w-]+)/i
		const search = value
			.split(/[\t\n\s]+/)
			.filter(Boolean)
			.filter(e => test1.test(e))
		localStorage.setItem('ExportChannelStats.search', JSON.stringify(search))
		setSearch(search);
	};
	const handleSearch = () => {
		setStatus(Status.isLoading)
		setContent([])
		Promise
			.all(search.map((url) => ChannelDao.getOne(url)))
			.then((result) => setContent(result))
			.finally(() => setStatus(Status.isContent))
	}

	const getObjectForUser = (channel: IChannel): object => {
		if(!channel.id) return { status: t('ExportChannelStats.channel.isNotFound') }
		return {
			status: t('ExportChannelStats.channel.isActive'),
			title: channel.title,
			link: channel.link ? `https://${channel.link}` : '',
			category: channel.category,
			participantsCount: channel.participantsCount,
			avgPostReach: channel.avgPostReach,
			username: channel.username,
			country: channel.country,
			language: channel.language,
			peerType: channel.peerType,
			advPostReach12h: channel.advPostReach12h,
			advPostReach24h: channel.advPostReach24h,
			advPostReach48h: channel.advPostReach48h,
			dailyReach: channel.dailyReach,
			ciIndex: channel.ciIndex,
			erPercent: channel.erPercent,
			errPercent: channel.errPercent,
			err24Percent: channel.err24Percent,
			forwardsCount: channel.forwardsCount,
			mentioningChannelsCount: channel.mentioningChannelsCount,
			mentionsCount: channel.mentionsCount,
			postsCount: channel.postsCount,
		}
	}


	const copyToClipboard = async (e: any) => {
		e.target.setAttribute('loading', 'true')
		try {
			const data = content
				.map(getObjectForUser)
				.map((row: any) => ColumnsForBuffer.map((c: any) => c.format(row[c.key])).join("\t"))
				.join('\n')

			await navigator.clipboard.writeText(data)

			api.success({
				message: t(`ExportChannelStats.copyToClipboard.successMessage.title`),
				description: t(`ExportChannelStats.copyToClipboard.successMessage.message`),
				showProgress: false,
				pauseOnHover: false,
				closeIcon: null,
				placement: 'topRight',
			})
		} catch {
			api.error({
				message: t(`ExportChannelStats.copyToClipboard.errorMessage.title`),
				description: t(`ExportChannelStats.copyToClipboard.errorMessage.message`),
				showProgress: false,
				pauseOnHover: false,
				closeIcon: null,
				placement: 'topRight',
			})
		}
	}

	return(
		<>
			<Space.Compact style={{width: '100%'}}>
				<SettingPopover open={!!settingsOpened} onOpenChange={handleSettingsOpenedChange} />
				<Popover
				content={<>
					<List
					style={{maxHeight: '50vh', paddingTop: 5, overflow: "auto"}}
					itemLayout="horizontal"
					loading={status === Status.isLoading}
					dataSource={search}
					locale={{
						emptyText: <Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={t('ExportChannelStats.searchArray.empty')}
						/>
					}}
					renderItem={(url, idx) => (
						<List.Item key={url} style={{height: 40, marginTop: 10, marginBottom: 0}}>
							<Paragraph
							copyable={{
								icon: [
									<CopyOutlined style={{color: '#9261f1'}}/>,
									<CheckOutlined style={{color: '#9261f1'}}/>,
								],
							}}
							ellipsis={{
								expandable: 'collapsible',
								symbol: (expanded) => expanded
									? <UpOutlined style={{color: '#9261f1'}}/>
									: <DownOutlined style={{color: '#9261f1'}}/>
							}}
							style={{left: 0, width: 450}}
							editable={{
								icon: <EditOutlined style={{color: '#9261f1'}}/>,
								onChange: (value) => handleSearchChange(
									search.map((e, i) => i === idx ? value : e ).join(' ')
								),
							}}
							>
								{url}
							</Paragraph>
						</List.Item>
					)}
					/>
				</>}
				placement="bottomRight"
				title={<div style={{paddingRight: 10, minWidth: 500}}>
					<Tooltip placement="top" title={t('ExportChannelStats.searchArray.titleHelp')}>
						<Badge count={<QuestionCircleOutlined/>} style={{ color: '#9261f1', paddingLeft: 20 }}>
							{t('ExportChannelStats.searchArray.title')}
						</Badge>
					</Tooltip>
				</div>}
				trigger="click"
				open={!!searchArrayOpened}
				onOpenChange={handleSearchArrayOpenedChange}>
					<Badge count={search.length} color="#9261f1" overflowCount={99} style={{zIndex: 1}}>
						<Avatar
							style={{
								borderRadius: 0,
								background: '#fff',
								border: '1px solid #d9d9d9',
								borderRight: 0,
								color: '#000',
								cursor: "pointer",
								userSelect: "none",
							}}
							shape="square"
							size="large">
							{ !!searchArrayOpened ? (
								<UpOutlined/>
							) : (
								<DownOutlined/>
							)}
						</Avatar>
					</Badge>
				</Popover>
				<Input placeholder={t(`ExportChannelStats.search.placeholder`)}
				size="large"
				value={search.join(', ')}
				onPressEnter={handleSearch}
				onChange={e => handleSearchChange(e.target.value)}
				onFocus={e => e.target.select()}
				disabled={Boolean(search.length)}
				/>
				<Button
				size="large"
				onClick={handleSearchClear}>
					<CloseOutlined />
				</Button>
				<Button
				type="primary"
				size="large"
				onClick={handleSearch}
				loading={ status === Status.isLoading }>
					{ t(`ExportChannelStats.search.button`) }
				</Button>
			</Space.Compact>
			<UsersManualExportButton t={t}/>
			{ status === Status.isEmpty && (
				<Result style={{paddingTop: '25vh'}}
				icon={<Empty description={false} />}
				status="warning"
				title={t(`ExportChannelStats.search.empty`)}
				/>
			)}
			{ status === Status.isContent && (
				<>
					<Flex justify='flex-end' align='flex-start' style={{marginTop: -20}}>
						<Button
						onClick={copyToClipboard}
						type="text"
						style={{color: "#9261f1"}}>
							{ t(`ExportChannelStats.copyToClipboard.action`) }
						</Button>
					</Flex>
					<Table
					virtual
					bordered
					size="small"
					style={{
						paddingTop: 5
					}}
					pagination={{pageSize: 999, hideOnSinglePage: true}}
					scroll={{
						x: 2400,
						y: 9000,
					}}
					columns={columns}
					dataSource={
						content.map((e) => ({key: uniqid(), ...getObjectForUser(e)}))
					}
					/>
				</>
			)}
			{ status === Status.isLoading && (
				<SpinLoading/>
			)}
			{contextHolder}
		</>
	);
}

export default ExportChannelStats
