import {Quotation} from "../types/Quotation";
import {ApiURL, Request} from "@services/api.service";

interface IQuotationsDao {
  getAll: () => Promise<Array<Quotation> | Error>
}

export default {
  getAll: (): Promise<Array<Quotation> | Error> => {
    return Request(ApiURL + `entity/quotation/_all`, {})
      .then((response: any) => {
        return response.map((e: Object) => new Quotation(e));
      })
      .catch((error) => {
        return error
      })
  }
} as IQuotationsDao


