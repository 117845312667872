import React, {useState} from "react";

import {Outlet, Link, useLocation} from "react-router-dom";
import {Layout, Menu, theme, Typography} from 'antd';
import {
  BorderlessTableOutlined,
  MessageOutlined,
  DollarOutlined,
} from '@ant-design/icons';

const { Content, Footer, Sider } = Layout;
const { Title } = Typography;

const menu = [
  {
    href: '/board/balance',
    icon: DollarOutlined,
    label: `Баланс`,
  },
  {
    href: '/board/export-posts',
    icon: MessageOutlined,
    label: `Посты`,
  },
  {
    href: '/board/export-channels',
    icon: BorderlessTableOutlined,
    label: `Каналы`,
  },
].map((e, idx) => ({
  key: e.href,
  icon: React.createElement(e.icon),
  label: <Link to={e.href}>{e.label}</Link>,
}));

const Board: React.FC<any> = () => {
  const { token } = theme.useToken();
  const location = useLocation();

  const [sidebarCollapsed, setSidebarCollapsed] = useState(true)

  return (
    <Layout hasSider style={{
      minHeight: '100vh',
    }}>
      <Sider
        collapsible
        collapsed={ sidebarCollapsed }
        onCollapse={(value) => setSidebarCollapsed(value)}
        style={{
          overflow: 'auto',
          height: '100vh',
          left: 0,
          top: 0,
          bottom: 0,
          position: 'fixed',
        }}
      >
        <a href="https://adsee.pro"
           target="_blank"
           rel="noreferrer"
           className="demo-logo-vertical"
           style={{
             padding: '20px 0',
             margin: 8,
             textAlign: 'center',
             background: token.colorBgContainer,
             borderRadius: token.borderRadiusLG,
             display: "block",
             cursor: "pointer",
           }}
        >
          <Title
            level={ sidebarCollapsed ? 5 : 4 }
            style={{
              margin: 0,
              userSelect: "none",
            }}
          >Ad<span style={{ color: '#ba80e6' }}>See</span></Title>
        </a>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]} items={menu}/>
      </Sider>
      <Layout>
        <Content
          style={{
            margin: 12,
            overflow: 'initial',
            marginLeft: sidebarCollapsed ? 90 : 210,
            transition: 'margin 0.2s',
          }}
        >
          <div
            style={{
              padding: 24,
              textAlign: 'center',
              background: token.colorBgContainer,
              borderRadius: token.borderRadiusLG,
              minHeight: '100%',
            }}
          >
            <Outlet/>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', padding: '6px 50px 16px 50px' }}>
          <div>AdSee ©{new Date().getFullYear()} Создано W-RED</div>
          <div>сборка {process.env.REACT_APP_VERSION}</div>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default Board
