import {Request, ApiURL} from "@services/api.service";

const login = (password: string) => {
  return Request(ApiURL + "auth/login", {
    headers: {
      "Content-type": "application/json"
    },
    method: "POST",
    body: JSON.stringify( { password },),
  }).then((response: any) => {
    localStorage.setItem("user", JSON.stringify(response));
    return response;
  })
}

const logout = () => {
  localStorage.removeItem("user");
};

const getToken = () => {
  try {
    const data = localStorage.getItem("user")
    if(!data) return;
    const user = JSON.parse(data)
    return user.authToken;
  } catch {
    return;
  }
}

const isLoggedIn = () => {
  try {
    const user = localStorage.getItem("user");
    if (!user) return false;
    JSON.parse(user)
    return true
  } catch {
    return false
  }
}

const authService = {
  login,
  logout,
  isLoggedIn,
  getToken,
};

export default authService;
